import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "อยากเริ่มเล่นเกม-ต้องทำไง-"
    }}>{`อยากเริ่มเล่นเกม ต้องทำไง ?`}</h3>
    <p>{`ก่อนที่จะเริ่มเล่นเกม ต้องทำการเชื่อมกระเป๋า Crypto Currency ของคุณเข้ากับเว็บแดชบอร์ดของเรา หลังจากนั้น คุณจะต้องมีเหรียญอย่างน้อย 1000 CLC
เพื่อซื้อตัวละครและผืนฟาร์มสำหรับเริ่มเล่นเกม`}</p>
    <p><img alt="howtostart" src={require("./public/images/white.png")} /></p>
    <p>{`สามารถซื้อตัวละครได้ที่หน้าแดชบอร์ด จะเลือกซื้อโดยการเปิดกล่องกาชา หรือจากตลาดก็ได้ และผืนฟาร์มก็ซื้อจากหน้าแดชบอร์ดเช่นกัน ถ้าผู้เล่นไม่มีผืนฟาร์สสักผืนเลย แล้วกดเข้าเกมไป จะเจอกับ
Pop-up เตือนให้ไปซื้อผืนฟาร์มก่อนเข้ามาเล่น`}</p>
    <p><img alt="howtostart" src={require("./public/images/howto-25.png")} /></p>
    <p><img alt="howtostart" src={require("./public/images/howto-2.png")} /></p>
    <p>{`เมื่อผู้เล่นมีผืนฟาร์มแล้ว กดเข้ามาในเกม จะเจอกับหน้าเลือกฟาร์ม ให้ผู้เล่นทำการเลือกผืนฟาร์มที่จะเข้าไปเล่นได้เลย`}</p>
    <p><img alt="howtostart" src={require("./public/images/howto-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      